.mini-gallery{
  width: 16%;
}
.imhs{
  width:16%;
}
img{
  width: fit-content;
}
Slide{
  /* width: fit-content !important; */
}
@media screen and (max-width: 1262px) {
  .imhs{
      width: 20%;
    }
  }
  @media screen and (max-width: 1000px) {
    .imhs{
      width: initial;
    }
  }
  @media screen and (max-width: 342px) {
    .imhs{
    width: 90%;
  }
}