
.AboutBanner{
  z-index:4444;
}
.heightt{
  height: 400px;
}
.caro{
  z-index: 9 !important;
}
@media only screen and (max-width: 534px) {
  .heightt{
    height: 300px;
  }
}
@media only screen and (max-width: 450px) {
  .heightt{
    height: 250px;
  }
}
@media only screen and (max-width: 350px) {
  .heightt{
    height: 350px;
  }
}
@media only screen and (max-width: 285px) {
  .heightt{
    height: 300px;
  }
}
.Aboutrectangles1{
  height: inherit;
  bottom: 0;
  position: absolute;
}
.Aboutrectangles2{
  height: inherit;
  position: absolute;
  right: 0;
  top: 0;
}
@media only screen and (max-width: 748px) {

  .Aboutrectangles1{
    width: 50%;
    height: auto;
  }
  .Aboutrectangles2{
    width: 50%;
    height: auto;
  }
}
@media only screen and (max-width: 300px) {
      .aboutwords{
      margin-left: -10px;
    }
}
