
.introtext{
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 9995;
  left: 55%;
  display: flex;
  color: azure;
  text-align: left;
  justify-content: center;
  flex-direction: column;
  font-weight:bold;
  font-size: 2.5rem;
}
@media only screen and (max-width: 768px) {
  .introtext{
    font-size: 2rem !important;
  }
}
@media only screen and (max-width: 490px) {
  .introtext{
    left: 47% !important;
    font-size: 1.6rem !important;
  }
.introtext3{
  /* margin-top: -30px; */
  opacity: 0;
  animation: fadeInFromBottom3 4s ease-in-out forwards;
  margin-left: 12px !important;
}
.introtext5{
  margin-left: 21px !important;
  /* margin-top: -30px; */
  opacity: 0;
  animation: fadeInFromBottom5 4s ease-in-out forwards;
}
}
.introtext1{
  opacity: 0;
  animation: fadeInFromBottom 4s ease-in-out forwards;
}
.introtext2{
  /* margin-top: -30px; */
  opacity: 0;
  animation: fadeInFromBottom2 4s ease-in-out forwards;
}
.introtext3{
  /* margin-top: -30px; */
  opacity: 0;
  animation: fadeInFromBottom3 4s ease-in-out forwards;
  margin-left: 15px;
}
.introtext4{
  /* margin-top: -30px; */
  opacity: 0;
  animation: fadeInFromBottom4 4s ease-in-out forwards;
}
.introtext5{
  margin-left: 32px;
  /* margin-top: -30px; */
  opacity: 0;
  animation: fadeInFromBottom5 4s ease-in-out forwards;
}

@keyframes fadeInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  10%{
    transform: translateY(0);
    opacity: 1;
  }
  
  50%{
    transform: translateY(0);
    opacity: 1;
  }
  60%{
    transform: translateY(-300%);
    opacity: 0;
  }
  100%{
    opacity: 0;
    transform: translateY(-300%);
  }
}
@keyframes fadeInFromBottom2 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  20%{
    transform: translateY(0);
    opacity: 1;
  }
  
  50%{
    transform: translateY(0);
    opacity: 1;
  }
  60%{
    transform: translateY(-80%);
    opacity: 1;
  }
  70%{
    transform: translateY(-300%);
    opacity: 0;
  }
  100%{
    opacity: 0;
    transform: translateY(-300%);
  }
}
@keyframes fadeInFromBottom3 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  10% {
    transform: translateY(100%);
    opacity: 0;
  }
  30%{
    transform: translateY(0);
    opacity: 1;
  }
  50%{
    transform: translateY(0);
    opacity: 1;
  }
  60%{
    transform: translateY(-80%);
    opacity: 1;
  }
  70%{
    transform: translateY(-160%);
    opacity: 1;
  }
  80%{
    transform: translateY(-300%);
    opacity: 0;
  }
  100%{
    opacity: 0;
    transform: translateY(-300%);
  }
}
@keyframes fadeInFromBottom4 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  20% {
    transform: translateY(100%);
    opacity: 0;
  }
  40%{
    transform: translateY(0);
    opacity: 1;
  }
  50%{
    transform: translateY(0);
    opacity: 1;
  }
  60%{
    transform: translateY(-80%);
    opacity: 1;
  }
  70%{
    transform: translateY(-160%);
    opacity: 1;
  }
  
  80%{
    transform: translateY(-240%);
    opacity: 1;
  }
  90%{
    transform: translateY(-300%);
    opacity: 0;
  }
  100%{
    opacity: 0;
    transform: translateY(-300%);
  }
}
@keyframes fadeInFromBottom5 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  30% {
    transform: translateY(100%);
    opacity: 0;
  }
  50%{
    transform: translateY(0);
    opacity: 1;
  }
  60%{
    transform: translateY(-80%);
    opacity: 1;
  }
  70%{
    transform: translateY(-160%);
    opacity: 1;
  }
  
  80%{
    transform: translateY(-240%);
    opacity: 1;
  }
  90%{
    transform: translateY(-320%);
    opacity: 1;
  }
  100%{
    transform: translateY(-400%);
    opacity: 0;
  }
}

/* styles.css */

.slide-in-div {
    width: 100%;
    height: 100vh;
    background-color: transparent;
    position: relative;
    overflow: hidden !important;
}

.slide-in {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.slide-in:nth-child(1) {
    animation: slide-in1 4s ease-in-out forwards;
  background-color: #946aef;
}

.slide-in:nth-child(2) {
    animation: slide-in2 4s ease-in-out forwards;
    background-color: #f2af1a;
}

.slide-in:nth-child(3) {
    animation: slide-in3 4s ease-in-out  forwards;
    background-color: #6363d3;
}

.slide-in:nth-child(4) {
    animation: slide-in4 4s ease-in-out  forwards;
    background-color: #01994c;
}

.slide-in:nth-child(5) {
    animation: slide-in5 4s ease-in-out  forwards;
    background-color: #f65c51;
}

@keyframes slide-in1 {
    0% {
        left: 100%;
    }
    10%{
      left: 0;
    }
    75%{
      left: 0;
    }
    90%{
      left: 0;
    }
    100% {
        left: -100%;
        display: none;
    }
}

@keyframes slide-in2 {
    0% {
        left: 100%;
    }
    20%{
      left: 20%;
    }
    40%{
      left: 20%;
    }
    50%{
      left: 20%;
    }
    60%{
      left: 0%;
    }
    90%{
      left: 0;
    }
    100% {
        left: -100%;
        display: none;
    }
}

@keyframes slide-in3 {
    0% {
        left: 100%;
    }
    10%{
      left: 100%;
    }
    30%{
      left: 40%;
    }
    40%{
      left: 40%;
    }
    50%{
      left: 40%;
    }
    60%{
      left: 20%;
    }
    70%{
      left: 0;
    }
    90%{
      left: 0;
    }
    100% {
        left: -100%;
        display: none;
    }
}

@keyframes slide-in4 {
    0% {
        left: 100%;
    }
    20% {
        left: 100%;
    }
    40%{
      left: 60%;
    }
    50%{
      left: 60%;
    }
    60%{
      left: 40%;
    }
    70%{
      left: 20%;
    }
    80%{
      left: 0;
    }
    90%{
      left: 0;
    }
    100% {
        left: -100%;
        display: none;
    }
}

@keyframes slide-in5 {
    0% {
        left: 100%;
    }
    30% {
        left: 100%;
    }
    50%{
      left: 80%;
    }
    60%{
      left: 60%;
    }
    70%{
      left: 40%;
    }
    80%{
      left: 20%;
    }
    90%{
      left: 0%;
    }
    100% {
        left: -100%;
        display: none;
    }
}





.hideslide{
  animation: hideslide 0.5s ease-out 4s forwards;
}

@keyframes hideslide {
  0% {
    display: block;
  }
  100%{
    display: none;
  }
}
