.herosideimg1{
    position: absolute;
    top: 25%;
    left: 5%;
    /* width: 120px; */
  }
  .herosideimg2{
    position: absolute;
    top: 25%;
    right: 5%;
    /* width: 120px; */
  }
  .img11{
    width: 120px;
  }
  .home{
    z-index:99999 !important;
    background-color: white !important;
  }
  .bel{
    z-index:999999 !important;
  }
  @media only screen and (max-width: 920px) {
      .img11{
        display: none;
      }
      .herosideimg1{
        display: none;
      }
      .herosideimg2{
        display: none;
      }
    }
  @media only screen and (max-width: 1020px) {
 .contactusBTN{
	display: flex;
	align-items: center;
	border-radius: 4px;
	background: #946aef;
	padding:18px 30px;
	height: 0px !important;
	font-size: 0.8rem;
	color: #fff;
	font-weight: bold;
	border: none;
	outline: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
  }
	.contactusBTN:hover {
	  transition: all 0.2s ease-in-out;
	  background: #f2af1a;
	  color: #fff;
	}
  }
  @media screen and (max-width: 370px) {
	.contactusBTN{
	  padding:15px 20px;
	  font-size: 0.6rem;
	  /* margin-right: 25px; */
	} 
}
