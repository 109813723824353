.carousell{
  width: 90%;
  position: absolute;
  bottom: 0%;;
}
.img1{
  margin-top:50px;
  padding-left: 30px ;
  margin-bottom:50px;
  /* z-index: 91 ; */
}