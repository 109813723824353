@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
	transition: ease-in-out 0.4s;
	font-family: 'Chakra Petch', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@layer components {
	.max-container {
		/* max-width: 1440px; */
		margin: 0 auto;
	}

	.input {
		@apply sm:flex-1 max-sm:w-full  leading-normal text-slate-gray pl-5 max-sm:p-5 outline-none sm:border-none border max-sm:border-slate-gray max-sm:rounded-full;
	}
}

@layer utilities {
	.padding {
		@apply sm:px-16 px-8 sm:py-24 py-12;
	}

	.padding-x {
		@apply sm:px-16 px-8;
	}

	.padding-y {
		@apply sm:py-24 py-12;
	}

	.padding-l {
		@apply sm:pl-16 pl-8;
	}

	.padding-r {
		@apply sm:pr-16 pr-8;
	}

	.padding-t {
		@apply sm:pt-24 pt-12;
	}

	.padding-b {
		@apply sm:pb-24 pb-12;
	}

	.info-text {
		@apply font-montserrat text-slate-gray text-lg leading-7;
	}
}
